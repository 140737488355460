import * as React from "react";

import Page from "../components/Page";
import LongText from "../components/LongText";
import DefaultLayout from "../layouts/default";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const Datenschutz = () => (
  <DefaultLayout>
    <Helmet>
      <title>
        404 – HICC<sup>®</sup>
      </title>
    </Helmet>
    <Page>
      <LongText background={"white"}>
        <Title>404: Seite nicht gefunden</Title>
        <Paragraph>
          Die aufgerufene Seite existiert nicht. <Link to="/">Startseite</Link>
        </Paragraph>
      </LongText>
    </Page>
  </DefaultLayout>
);

export default Datenschutz;
